$primary: #202020;
$primary-hover: #000;

$secondary: #9097c4;
$secondary-hover: #7780b7;

$success: #00c07e;
$success-hover: #009a65;

$danger: #fc5661;
$danger-hover: #fb2432;

$warning: #f5a623;
$warning-hover: #e7940b;

$info: #0198e8;
$info-hover: #0177b5;

$light: #f8f9fa;
$light-hover: #e2e6ea;

$dark: #3d4465;
$dark-hover: #2f344d;